<template>
  <section class="container">
    <b-field label="Farm version">
      {{farmVersion}}
    </b-field>
    <b-field label="Admin">
      <b-input v-model="farmStorage.admin"></b-input>
    </b-field>
    <b-field
      label="Frozen"
      title="Creates frozen farm. Only FA2 for stake and no deposit fees in this mode"
      message="Creates frozen farm. Only FA2 for stake and no deposit fees in this mode"
    >
      <b-switch v-model="frozen" />
    </b-field>
    <b-radio :disabled="frozen" v-model="stakeTokenType" name="st" native-value="fa2">
      FA2
    </b-radio>
    <b-radio :disabled="frozen" v-model="stakeTokenType" name="st" native-value="fa12">
      FA12
    </b-radio>
    <b-field label="Stake Token Address">
      <b-input v-model="stakeTokenAddress"></b-input>
    </b-field>
    <b-field label="Stake Token Id">
      <b-numberinput
        v-model="stakeTokenId"
        :disabled="stakeTokenType == 'fa12'"
        min="0"
      ></b-numberinput>
    </b-field>
    <b-radio v-model="rewardTokenType" name="rt" native-value="fa2">
      FA2
    </b-radio>
    <b-radio v-model="rewardTokenType" name="rt" native-value="fa12">
      FA12
    </b-radio>
    <b-field label="Reward Token Address">
      <b-input v-model="rewardTokenAddress"></b-input>
    </b-field>
    <b-field label="Reward Token Id">
      <b-numberinput
        v-model="rewardTokenId"
        :disabled="rewardTokenType == 'fa12'"
        min="0"
      ></b-numberinput>
    </b-field>
    <b-field label="Reward per second">
      <b-numberinput
        v-model="farmStorage.config.reward_amount_per_sec"
        min="0"
      ></b-numberinput>
    </b-field>
    <b-field
      label="Automint"
      title="If true it will increase mint amount if existing balance is low"
      message="If true it will increase mint amount if existing balance is low"
    >
      <b-switch v-model="farmStorage.config.automint" />
    </b-field>
    <b-field label="Referal stake permil">
      <b-numberinput
        v-model="farmStorage.config.referer_upline_permil"
        min="0"
      ></b-numberinput>
    </b-field>
    <b-field label="Reward fees">
      <div class="container">
      <ul>
        <li
          v-for="feestep in farmStorage.fees_cfg"
          v-bind:key="feestep.from_secs"
        >
          {{ feestep.from_secs }} - {{ feestep.fee }}
        </li>
      </ul>
      <b-button v-on:click="removeLastRewardFee()">-</b-button>
      <b-button v-on:click="showRewardFee()">+</b-button>
      </div>
    </b-field>

    <b-field v-if="!frozen" label="Unstake fees">
    <div class="container">
      <ul>
        <li
          v-for="feestep in farmStorage.deposit_fees_cfg"
          v-bind:key="feestep.from_secs"
        >
          {{ feestep.from_secs }} - {{ feestep.fee }}
        </li>
      </ul>
      <b-button v-on:click="removeLastDepositFee()">-</b-button>
      <b-button v-on:click="showDepositFee()">+</b-button>
    </div>
    </b-field>
    <br />
    <b-button type="is-primary" v-if="!inProgress && !error" @click="deploy">Deploy</b-button>
    <b-button type="is-primary" v-if="inProgress && !error" loading>Loading</b-button>
    <b-button v-if="error" type="is-danger" @click="error=''">{{error}}</b-button>

    <span v-if="contractAddress">
      Contract deployed: {{ contractAddress }}
    </span>
    <b-modal
      v-on:data-submit="addRewardFee"
      v-model="addFeeModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="add fee"
      aria-modal
    >
      <template #default="props">
        <fee-step @close="props.close" />
      </template>
    </b-modal>
    <b-modal
      v-on:data-submit="addDepositFee"
      v-model="addDepositFeeModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="add fee"
      aria-modal
    >
      <template #default="props">
        <fee-step @close="props.close" />
      </template>
    </b-modal>
  </section>
</template>

<script>
import FeeStep from "./FeeStep.vue";
const { MichelsonMap } = require("@taquito/michelson-encoder");

export default {
  components: { "fee-step": FeeStep },
  data: function () {
    return {
      farmStorage: {
        version: "",
        admin: window.deployer.account.address,
        stake_token: {},
        reward_token: {},
        frozen: false,
        config: {
          reward_amount_per_sec: 0,
          referer_upline_permil: 0,
          automint: false
        },
        accounts: new MichelsonMap(),
        total_stack: "0",
        total_virt_stack: "0",
        reward_per_stake: "0",
        last_update_time: "0",
        total_rewards_left: "0",
        reward_balance: "0",
        total_fees: "0",
        fees_cfg: [],
        total_deposit_fees: "0",
        deposit_fees_cfg: [],
      },
      deployer: window.deployer,
      inProgress: false,
      error: "",
      contractAddress: null,
      stakeTokenType: "fa2",
      stakeTokenAddress: null,
      stakeTokenId: 0,
      rewardTokenType: "fa2",
      rewardTokenAddress: null,
      rewardTokenId: 0,
      addFeeModalActive: false,
      addDepositFeeModalActive: false,
      frozen: false,
      farmVersion: "6",
    };
  },
  watch: {
    frozen(val) {
      // clean fee and set FA2 for stake token
      if (val) {
        this.deposit_fees_cfg = [];
        this.stakeTokenType = 'fa2';
      }
    }
  },
  methods: {
    deploy() {
      let fixedFarmStorage = JSON.parse(JSON.stringify(this.farmStorage));
      fixedFarmStorage.accounts = new MichelsonMap();
      fixedFarmStorage.stake_token = this.stakeTokenType === 'fa2' ? {
        'fa2': {11: this.stakeTokenAddress, 12: this.stakeTokenId}
      } : {'fa12': this.stakeTokenAddress};
      fixedFarmStorage.reward_token = this.rewardTokenType === 'fa2' ? {
        'fa2': {10: this.rewardTokenAddress, 11: this.rewardTokenId}
      } : {'fa12': this.rewardTokenAddress};
      fixedFarmStorage.frozen = this.frozen;
      fixedFarmStorage.config.reward_amount_per_sec =
        "" + fixedFarmStorage.config.reward_amount_per_sec;
      fixedFarmStorage.config.referer_upline_permil =
        "" + fixedFarmStorage.config.referer_upline_permil;
      fixedFarmStorage.version = `spacefarm/universal?v=` + this.farmVersion;

      console.log(this.farmStorage);
      console.log(window.deployer.tezos);
      this.inProgress = true;
      window.deployer
        .deployFarm(this.stakeTokenType, this.rewardTokenType, fixedFarmStorage)
        .then((contract) => {
          this.inProgress = false;
          this.contractAddress = contract.address;
        })
        .catch((e) => {
          this.inProgress = false;
          console.error(e);
          this.error = e.message ? e.message : JSON.stringify(e);
        });
    },
    getAccountAddress() {
      return window.deployer.account.address;
    },
    showRewardFee() {
      this.addFeeModalActive = true;
    },
    addRewardFee(fee_step) {
      this.farmStorage.fees_cfg.push(fee_step);
    },
    removeLastRewardFee() {
      this.farmStorage.fees_cfg.pop();
    },
    showDepositFee() {
      this.addDepositFeeModalActive = true;
    },
    addDepositFee(fee_step) {
      this.farmStorage.deposit_fees_cfg.push(fee_step);
    },
    removeLastDepositFee() {
      this.farmStorage.deposit_fees_cfg.pop();
    },
  },
};
</script>