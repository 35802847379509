<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">add fee</p>
    </header>
    <section class="modal-card-body">
      <b-field label="From (sec)">
        <b-numberinput min="0" v-model="from_secs"></b-numberinput>
      </b-field>
      <b-field label="Fee (%)">
        <b-numberinput min="0" max="100" v-model="fee"></b-numberinput>
      </b-field>
      <b-button v-on:click="submit()">ok</b-button>
    </section>
  </div>
</template>
<script>
export default {
  model: {
    event: 'data-submit'
  },
  data: function () {
    return {
      from_secs: 0,
      fee: 0,
    };
  },
  methods: {
    submit() {
      this.$emit('close')
      this.$parent.$emit("data-submit", { from_secs: this.from_secs, fee: this.fee });
    },
  },
};
</script>