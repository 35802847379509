import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';

window.netName = 'mainnet';
const testnetFlag = new URLSearchParams(window.location.search).get('testnet');
if (testnetFlag && testnetFlag === "true" || window.network === "testnet") {
  window.netName = 'testnet';
}

class Deployer {
  constructor() {
    this.setNetwork(window.netName)
  }

  setNetwork(netName) {
    if(netName === 'mainnet') {
      this.network = {
        name: "mainnet",
        rpcUrl: "https://mainnet-node.madfish.solutions",
        type: "mainnet"
      }
    } else if (netName === 'testnet') {
      this.network = {
        name: "granadanet",
        rpcUrl: "https://granadanet.smartpy.io",
        type: "granadanet"
      }
    }
    this.tezos = new TezosToolkit(this.network.rpcUrl);
    const options = {
      name: 'Space Farm',
      //iconUrl: 'https://tezostaquito.io/img/favicon.png',
      preferredNetwork: this.network.type,
      /*eventHandlers: {
        PERMISSION_REQUEST_SUCCESS: {
          handler: async (data) => {
            console.log('permission data:', data);
          },
        },
      },*/
    };
    this.wallet = new BeaconWallet(options);
    this.tezos.setWalletProvider(this.wallet);
    this.account = null;
  }

  async login() {
    await this.wallet.requestPermissions({ network: { name: this.network.name, rpcUrl: this.network.rpc, type: this.network.type } });
    this.account = await this.wallet.client.getActiveAccount();
    this.tezos.setProvider(this.wallet);
    return this.account
  }

  logout() {
    this.account = null;
  }

  async getFarmCode() {
    return fetch(`farms/universal-farm.json`).then(r => r.json())
  }

  async deployFarm(stakeType, rewardType, farmStorage) {
    const farmCode = await this.getFarmCode(stakeType, rewardType);
    const operation = await this.tezos.wallet.originate({
      code: JSON.parse(farmCode.michelson),
      storage: farmStorage,
    }).send();

    await operation.confirmation();
  
    const contract = await operation.contract()
    
    console.log(operation)
    console.log('Contract deployed: ' + contract.address)
    return contract;
  }
}

window.deployer = new Deployer()

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
